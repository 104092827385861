<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="#174282"
    dark
    max-height="200"
  >
    <div v-if="isMobile">
      <div v-if="isAdmin!=1">
        <v-btn v-for="(item,i) in items" :key="i"
          class="ml-2"
          min-width="0"
          text
          :to="item.to"
        >{{item.title}}
        </v-btn>
        <v-btn v-if="!userId"
          class="ml-2"
          min-width="0"
          text
          to="/sign_in"
        >
          Inscription
      </v-btn>
      <v-btn v-else
          class="ml-2"
          min-width="0"
          text
          to="/profile"
        >
          Mes inscriptions
      </v-btn>
      </div>

      <div v-else> 
        <v-menu
          bottom
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-2"
              min-width="0"
              text
              v-bind="attrs"
              v-on="on"
            >
            Accueil
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="(item, i) in items" :key="i" 
              :to="item.to">
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn  v-for="(item,j) in items_is_admin" :key="j"
          class="ml-2"
          min-width="0"
          text
          :to="item.to"
        >{{item.title}}
        </v-btn>
      </div>
    </div>
    
    <v-menu v-else bottom right>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-for="(item, i) in items" :key="i" 
          :to="item.to">
          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
        <div v-if="isAdmin==1">
          <v-list-item v-for="(item, j) in items_is_admin" :key="j"
          :to="item.to">
            <v-list-item-title >
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </div>
        <v-list-item to="/sign_in" v-if="!userId">
            <v-list-item-title>
              Inscription
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/profile" v-else>
            <v-list-item-title>
              Mes inscriptions
            </v-list-item-title>
          </v-list-item>
      </v-list>
    </v-menu>
    <v-spacer/>
    <strong class="mb-2" style="color:Orange" v-if="userName!=null">{{userName}} {{userLastName}}</strong>
     <v-btn v-if="userId"
        class="ml-2"
        min-width="0"
        text
        @click="logOut"
      >
        Deconnexion
      </v-btn>

      <v-btn v-else
        class="ml-2"
        min-width="0"
        text
        to="/login"
      >
        Connexion
      </v-btn>  
    
  </v-app-bar>
</template>

<script>

  // Utilities
  import { mapState } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      notifications: [],
      items: [
        { title: 'Accueil', to: '/'},
        //{ title: 'Ateliers', to: '/ateliers'},
        //{ title: 'Intervenants', to: '/intervenants'},
        { title: 'Accès', to: '/access' },
        //{ title: 'Mes inscriptions', to: '/profile' },
      ],
      items_is_admin: [
        //{ title: 'Ajout', to: '/add_event' },
        { title: 'Inscriptions', to: '/Inscriptions' },
        //{ title: 'Planning', to: '/Planning' },
        //{ title: 'Boxes', to: '/Distribution' },
        //{ title: 'Extraction', to: '/Extraction' },
        
      ]
    }),

    computed: {
      ...mapState(['userId', 'userName', 'userLastName', 'isAdmin']),
      isMobile() {
        return this.$vuetify.breakpoint.lgOnly;
      }
    },
    methods: {
      logOut () {
        this.$store.dispatch('logOutUser')
        window.location.href = ''
      },
      
    },
  }
</script>
